<template>
  <div class="auth-body ribbon-container" :style="{display: displayValue}">

    <div class="auth-el">
      <auth-card>
        <div class="password-reset-form">
          <v-form v-model="isValid" ref="form" @submit.prevent="resetPassword">
            <v-text-field
                readonly
                :value="phoneNumber"
                class="inputNumber input-field-size"
                label="Mobile Number"
                placeholder="Mobile Number"
                outlined
                type="text"
            ></v-text-field>
            <v-text-field
                v-model="password"
                :rules="[passwordRules.required, passwordRules.policy]"
                @keyup="matchPasswords"
                class="input-field-size"
                label="Password"
                placeholder="Password"
                outlined
                type="password"
            ></v-text-field>
            <v-text-field
                v-model="confirmPassword"
                :rules="[passwordRules.required, passwordRules.policy, passwordRules.passwordMatch]"
                class="input-field-size"
                label="Confirm Password"
                placeholder="Confirm Password"
                outlined
                type="password"
            ></v-text-field>
            <v-btn
                class="reset-btn"
                color="primary"
                :disabled="!isValid"
                required
                style="font-size: 22px"
                type="submit"
            >
              Reset Password
            </v-btn>
          </v-form>
        </div>
      </auth-card>
    </div>
  </div>
</template>

<script>
import AuthCard from "@/components/auth/components/AuthCard";
import {PASSWORD_POLICY} from "cpp-js-util/CppUtil";
import utilHelper from "@/util/UtilHelper";
import encryptionLib from "cpp-js-util/encryption";

export default {
  components: {
    AuthCard
  },
  data() {
    return {
      displayValue: 'none',
      isValid: false,
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      token: '',
      passwordRules: {
        required: v => !!v || 'Password is required',
        policy: v => !!v && PASSWORD_POLICY.test(v) ||
            'Password must have 8+ characters and at least one uppercase, lowercase, digit & special character',
        passwordMatch: v => !!v && v === this.password || 'Password must match'
      },
      autofilled: false,
    }
  },
  mixins: [utilHelper],
  created() {
    this.$feedback.showLoading()
    this.token = this.$route.params.token;
    this.$cppClient.post('reset-password/token', {
      token: this.token
    }).then(({data}) => {
      this.$feedback.hideLoading()
      this.displayValue = ''
      this.phoneNumber = this.stripCountryCode(data.username);
    }).catch(err => {
      this.$feedback.hideLoading()
      this.$logger.loggable(err).log()
      this.$router.push({name: 'error', params: {error: this.$lodash.get(err, 'response.data')}})
    })
  },
  methods: {
    async resetPassword() {
      let payload = {token: this.token, password: this.password, phoneNumber: this.phoneNumber,}
      let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
      payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

      this.$feedback.showLoading()
      this.$cppClient.post('reset-password', payload).then(() => {
        this.$feedback.showSuccessMessage('Password reset successful. Please login')
        this.$router.push({name: 'sign-in'})
      }).catch(err => {
        this.$logger.loggable(err).log()
        this.$feedback.showFailed(err.response)
      })
    },
    matchPasswords() {
      if (this.confirmPassword) {
        this.$refs.form.validate()
      }
    }
  }
}
</script>

<style scoped>
.password-reset-form .input-el {
  margin: 35px 0 !important;
}

.reset-btn {
  font-size: 24px !important;
  letter-spacing: normal !important;
  margin-top: 10px;
  margin-bottom: 40px;
  width: 329px;
  height: 39px;
}

.input-field-size {
  margin-bottom: 42px;
  width: 329px !important;
  height: 51px;
  display: inline-block;
}

@media screen and (max-width: 425px) {
  .input-field-size {
    width: 100%;
    margin-bottom: 52px;;
  }

  .reset-btn {
    width: 100% !important;
    display: inline-block;
  }

  .password-reset-form {
    display: inline-block;
    width: 261px !important;
  }
}
</style>
